<template>
<PageWithHeader>
  <template #title>
    <t path="xmasaddress_2">Edit the delivery address</t>
  </template>
  <div class="px-16">
    <t custom #="{td, t}">
      <Holder :content="loaded" :skeleton-repeat="2" holder-class="px-12">
        <Form ref="form" v-if="loaded" :schema="schema" @submit="bind" #="{ values }" class="my-16">
          <FInput name="recipientName" :placeholder="t('xmasaddress_3') || `Recipient's name`"
            class="block" />
          <FInput name="recipientMobile" :placeholder="t('xmasaddress_4') || `Phone number`"
            class="block" />
          <FInput name="recipientCity" :placeholder="t('xmasaddress_5') || `City`"
            class="block" />
          <FInput name="recipientProvince"
            :placeholder="t('xmasaddress_6') || `State / Province / Oblast`"
            class="block" />
          <FInput name="recipientZipCode"
            :placeholder="t('xmasaddress_7') || `Zip Code / Post Cade`"
            class="block" />
          <Textarea
            name="recipientAddress"
            :placeholder="t('xmasaddress_8') || 'Detailed  Address'"
            rows="4"
            class="mt-12 block p-8"
          />
        </Form>
      </Holder>
    </t>
  </div>
  <template #footer>
    <Button class="block primary f-lg f-bold" @click="submit" :progress="progress">
      <t path="xmasaddress_9" custom #="{td}">{{td || 'Submit'}}</t>
    </Button>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { getAddressSchema } from '@/pages/setting/setting'
import Holder from '@/provider/Holder.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { Data } from '@/types'
import Form from 'common/form/Form.vue'
import FInput from 'common/form/inputs/FormInput.vue'
import Textarea from 'common/form/inputs/Textarea.vue'
import { defineComponent, ref, shallowRef } from 'vue'
import { useRouter } from 'vue-router'
import { readInputInfo, useEditAddress } from '@/events/christmas/christmas.api'

export default defineComponent({
  name: 'AddressEdit',
  components: { Button, Form, FInput, PageWithHeader, Textarea, Holder },
  setup () {
    const { request, progress } = useEditAddress()
    const router = useRouter()
    const form = ref<HTMLFormElement | null>(null)
    const schema = shallowRef({})
    const loaded = shallowRef(false)
    const formModel = shallowRef({
      recipientAddress: '',
      recipientCity: '',
      recipientMobile: '',
      recipientName: '',
      recipientProvince: '',
      recipientZipCode: '',
    })

    const getInfo = () => {
      readInputInfo().then(res => {
        formModel.value = res
        schema.value = getAddressSchema(res)
        loaded.value = true
      })
    }

    getInfo()

    return {
      form,
      formModel,
      schema,
      loaded,
      bind (data: Data) {
        return request(data).then(() => {
          router.back()
        })
      },
      progress,
      submit () {
        (form.value as HTMLFormElement).submit()
      },
    }
  },
})
</script>
<style scoped lang="scss">
.form-item:not(:first-child) {
  margin-top: 8px;
}

.face {
  pointer-events: none;
}
</style>
